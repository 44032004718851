import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { post_raw } from "util/requests";
import styles from "../signin/index.module.css";
import ctms_logo from "assets/logo.png";
import router from "views/routes";
import { useParams } from "react-router-dom";

const error_message = "Sorry, your login was not correct.";

export default function PasswordResetForm() {
  const { invite_token } = useParams();
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSent, setIsSent] = useState(false);
  const [isValidToken, setIsValidToken] = useState(null);

  const password_valid = () =>
    password &&
    password2 &&
    password === password2 &&
    /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-#$\.%&*_!\(\)|])(?=.*[a-zA-Z]).{8,20}/.test(
      password.trim(),
    )
      ? true
      : false;

  useEffect(() => {
    post_raw("/auth/check_invite_token", { token: invite_token })
      .then(() => {
        setIsValidToken(true);
      })
      .catch((err) => {
        setIsValidToken(false);
      });
  }, []);

  const handleReset = () => {
    if (is_loading) return;

    setIsLoading(true);
    post_raw("/auth/invite_registration_complete", {
      token: invite_token,
      password,
    }).then(() => {
      setIsLoading(false);
      setIsSent(true);
    });
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "password") setPassword(e.target.value);
    else if (key === "password2") setPassword2(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.key === "Enter") {
      handleReset();
    }
  };

  console.log(password, password2, password_valid());

  return (
    <div className={styles.wrapper}>
      {!isSent ? (
        <div className={styles.login_card}>
          <div className={styles.logo_wrapper}>
            <img src={ctms_logo} width="80px" className="logo" />
          </div>
          {isValidToken === true ? (
            <React.Fragment>
              <h2 style={{ color: "#333" }}>
                Enter a New Password to Complete Your Registration
              </h2>
              <div style={{ textAlign: "left" }}>
                <div style={{ marginBottom: 4 }}>Passwords must:</div>
                <div style={{ paddingLeft: 10 }}>
                  <div style={{ marginBottom: 4 }}>
                    • be 8-20 characters in length
                  </div>
                  <div style={{ marginBottom: 4 }}>
                    • contain a lower case and upper case character
                  </div>
                  <div style={{ marginBottom: 4 }}>• contain a number</div>
                  <div style={{ marginBottom: 4 }}>
                    • contain a special character like:-#$.%&*_!()|
                  </div>
                </div>
              </div>
              <div className={styles.margin_b_1}>
                <TextField
                  value={password}
                  label="New Password"
                  variant="standard"
                  onChange={handleFieldChange("password")}
                  onKeyDown={onKeyDown}
                  type="password"
                />
              </div>
              <div className={styles.margin_b_1}>
                <TextField
                  value={password2}
                  label="New Password Again"
                  variant="standard"
                  onChange={handleFieldChange("password2")}
                  onKeyDown={onKeyDown}
                  type="password"
                />
              </div>

              <LoadingButton
                variant="contained"
                loading={is_loading}
                onClick={handleReset}
                disabled={!password_valid()}
              >
                SUBMIT
              </LoadingButton>
              {errorMessage ? <div>{errorMessage}</div> : null}
            </React.Fragment>
          ) : null}
          {isValidToken === false ? (
            <div style={{ marginTop: 40 }}>
              This link is either invalid or has expired.
            </div>
          ) : null}
          {isValidToken === null ? (
            <div style={{ marginTop: 40 }}>Loading</div>
          ) : null}
        </div>
      ) : (
        <div className={styles.login_card}>
          <div className={styles.logo_wrapper}>
            <img src={ctms_logo} width="80px" className="logo" />
          </div>

          <div style={{ marginTop: 40 }}>
            Your account setup is complete. Please sign in.
          </div>
          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={() => router.navigate("/")}
            style={{ width: 200, alignSelf: "center", marginTop: 20 }}
          >
            SIGN IN
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
