import { useEffect, useState } from "react";
import styles from "./replace_modal.module.css";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ReplaceModal(props) {
  const { isOpen, close, onYes, data } = props;
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.modal_wrapper}>
        <div
          className={styles.modal_title}
        >{`File already exists, replace it?`}</div>
        <div className={styles.modal_body}></div>

        <div className={styles.modal_button_wrapper}>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => {
              onYes(value);
            }}
          >
            Yes
          </LoadingButton>
          <LoadingButton
            disabled={false}
            variant="outlined"
            loading={false}
            onClick={close}
          >
            No
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
