import { useState } from "react";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { post } from "util/requests";

export default function AdminInterface() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const onKeyDown = () => {};
  const handleFieldChange = () => {};
  const handleInvite = () => {
    post("/admin/invite_user", {
      firstName,
      lastName,
      email,
    });
  };
  return (
    <div>
      <div>
        <TextField
          label="First Name"
          variant="standard"
          onChange={(e) => setFirstName(e.target.value)}
          onKeyDown={onKeyDown}
          value={firstName}
        />
      </div>
      <div>
        <TextField
          label="Last Name"
          variant="standard"
          onChange={(e) => setLastName(e.target.value)}
          onKeyDown={onKeyDown}
          value={lastName}
        />
      </div>
      <div>
        <TextField
          label="email"
          variant="standard"
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={onKeyDown}
          value={email}
        />
      </div>
      <div>
        <LoadingButton
          variant="contained"
          loading={false}
          onClick={handleInvite}
          style={{ width: 200, alignSelf: "center", marginTop: 20 }}
        >
          INVITE
        </LoadingButton>
      </div>
    </div>
  );
}
