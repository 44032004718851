import { useEffect, useState } from "react";
import styles from "./delete_asset_modal.module.css";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function DeleteFolderModal(props) {
  const { isOpen, close, onYes, selectedFiles } = props;
  const [value, setValue] = useState("");

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.modal_wrapper}>
        <div
          className={styles.modal_title}
        >{`Are you sure you want to delete:`}</div>
        <div className={styles.modal_body}>
          {selectedFiles.map((file) => (
            <div className={styles.name}>{file.name}</div>
          ))}
        </div>
        <div className={styles.modal_button_wrapper}>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => onYes(value)}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            disabled={false}
            variant="outlined"
            loading={false}
            onClick={close}
          >
            Cancel
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
