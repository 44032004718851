import React, { Component } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import FileInterface from "views/file_interface";
import AdminInterface from "views/admin";

// const ProtectedRoute = (props) => {
//   const { permissions, required } = props;
//   return permissions.includes(required) ? (
//     <Outlet />
//   ) : (
//     <Navigate to={"/subjects"} />
//   );
// };

export default function Router() {
  const user_data = useSelector((state) => state.user.userData);
  return (
    <Routes>
      <Route path="files" element={<FileInterface />} />
      <Route path="admin" element={<AdminInterface />} />
    </Routes>
  );
}
