import { cloneDeep } from "lodash";
const initState = { files: [], refresh_file_list: null };

export default function filesReducer(state = initState, action) {
  let _files;
  switch (action.type) {
    case "add_files":
      _files = [...state.files];
      _files = _files.filter((f) => f.percentage !== 100);
      _files = _files.concat(action.payload);
      return { ...state, files: _files };
    case "update_percentage":
      _files = cloneDeep(state.files);
      let name = action.payload.name;
      let percentage = action.payload.percentage;
      let file = _files.find((f) => f.name === name);
      file.percentage = percentage;
      return { ...state, files: _files };
    case "refresh_file_list":
      return { ...state, refresh_file_list: Math.random() };
    case "cancel_uploads":
      return { ...state, files: [] };
    default:
      return state;
  }
}
