import { Component } from "react";

import Routes from "./routes";

export default class BaseRoute extends Component {
  render() {
    // const { is_loading, showing_module_bar } = this.state;
    return (
      <div className="full_page">
        <Routes />
      </div>
    );
  }
}
