import React, { useEffect, useRef } from "react";
import styles from "./header.module.css";

import act_global_logo from "assets/basking_logo.jpg";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { logout } from "util/auth";

export default function Header(props) {
  const ref = useRef(null);

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.hflex}>
        <div ref={ref} className={styles.apps_icon_wrapper}></div>
        <img src={act_global_logo} height="40px" className={styles.logo} />
        <div className={styles.mulish_title}>Core Lab</div>
      </div>
      <div className={styles.settings_icons_wrapper}>
        {/*<SettingsOutlinedIcon
          sx={{
            color: "#555",
            fontSize: "26px",
            lineHeight: "26px",
            cursor: "pointer",
          }}
          onClick={() => {}}
        />*/}
        <div className={styles.logout} onClick={logout}>
          Logout
        </div>
      </div>
    </div>
  );
}
