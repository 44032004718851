export const addFiles = (file_array) => {
  return { type: "add_files", payload: file_array };
};
export const updatePercentage = (name, percentage, location_uuid) => {
  return {
    type: "update_percentage",
    payload: { name, percentage, location_uuid },
  };
};

export const refreshFileList = () => {
  return { type: "refresh_file_list" };
};

export const cancelUploads = () => {
  return { type: "cancel_uploads" };
};
