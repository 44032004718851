import { useEffect, useState } from "react";
import styles from "./upload_status.module.css";
import { useSelector, useDispatch } from "react-redux";
import { handleFileUpload } from "./helpers";
import { updatePercentage, cancelUploads } from "_redux/files/actions";
import axios from "axios";

export default function UploadStatus(props) {
  const files = useSelector((state) => state.filesReducer.files);
  const dispatch = useDispatch();

  const [showing, setShowing] = useState(false);
  const [sourceToken, setSourceToken] = useState(null);

  const setProgress = (filename, percentage) => {
    console.log("setting progress", filename, percentage);
    dispatch(updatePercentage(filename, percentage));
  };

  useEffect(() => {
    if (files.length) {
      setShowing(true);
      if (files[0].percentage === 0) {
        const source = axios.CancelToken.source();
        setSourceToken(source);
        handleFileUpload(
          dispatch,
          global.files[files[0].name],
          setProgress,
          source,
        );
      }
    }
  }, [files]);

  const show_close = () => {
    return files.filter((f) => f.percentage !== 100).length === 0;
  };

  if (!showing) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {show_close() ? (
          <div className={styles.title}>Uploading complete</div>
        ) : (
          <div className={styles.title}>
            Uploading {files.length} item{files.length === 1 ? "" : "s"}
          </div>
        )}
        {show_close() ? (
          <div className={styles.cancel} onClick={() => setShowing(false)}>
            close
          </div>
        ) : (
          <div
            className={styles.cancel}
            onClick={() => {
              if (sourceToken) {
                sourceToken.cancel();
                setSourceToken(null);
              }
              dispatch(cancelUploads());
              setShowing(false);
            }}
          >
            cancel
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {files.map((file) => (
          <div className={styles.row} key={file.name}>
            <div className={styles.name}>{file.name}</div>
            <div className={styles.percentage}>
              {Math.floor(file.percentage)}%
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
