import { useEffect, useState } from "react";
import styles from "./new_folder_modal.module.css";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function NewFolderModal(props) {
  const { isOpen, close, onYes, data } = props;
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.modal_wrapper}>
        <div className={styles.modal_title}>{`New Folder Name`}</div>
        <div className={styles.modal_body}>
          <TextField
            type="text"
            placeholder="Name"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setMessage("");
              // let value = e.target.value;
              // if (/^([0-9]+){0,3}$/.test(value)) {
              //   setSystolic(value);
              // }
              // if (is_error({ systolic: value, diastolic }, "both")) {
              //   setParentFieldError && setParentFieldError(key_id, true);
              // } else {
              //   setParentFieldError && setParentFieldError(key_id, false);
              // }
            }}
            onKeyDown={() => {}}
            onBlur={() => {
              // updateValue("systolic", systolic);
            }}
            inputProps={{ maxLength: 100 }}
            autoComplete="off"
            sx={{
              "& .MuiInputBase-input": {
                padding: "5px",
                textAlign: "center",
                width: "300px",
                fontSize: 14,
              },
            }}
          />
          {message}
        </div>

        <div className={styles.modal_button_wrapper}>
          <LoadingButton
            disabled={false}
            variant="contained"
            loading={false}
            onClick={() => {
              if (
                data
                  .filter((d) => d.type === "folder")
                  .find((d) => d.name === value)
              ) {
                setMessage("Folder name already exists.");
              } else {
                onYes(value);
                setValue("");
              }
            }}
          >
            Create
          </LoadingButton>
          <LoadingButton
            disabled={false}
            variant="outlined"
            loading={false}
            onClick={close}
          >
            Cancel
          </LoadingButton>
        </div>
      </div>
    </Dialog>
  );
}
