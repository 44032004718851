import React from "react";
import styles from "./crumb.module.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

export default function BreadCrumbs(props) {
  const { location = [], fetch_path } = props;

  const render_crumb = (crumb, index) => {
    return (
      <React.Fragment>
        <div
          onClick={() =>
            location.length - 1 !== index &&
            fetch_path({ uuid: crumb.uuid }, "up")
          }
          className={location.length - 1 !== index && styles.crumb_clickable}
        >
          {crumb.name}
        </div>
        {location.length - 1 !== index ? (
          <div className={styles.carat}>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "12px" }} />
          </div>
        ) : null}
      </React.Fragment>
    );
  };
  return (
    <div className={styles.crumb_wrapper}>/{location.map(render_crumb)}</div>
  );
}
