import axios from "axios";
import { post } from "util/requests";
import { useSelector, useDispatch } from "react-redux";
import { addFiles, refreshFileList } from "_redux/files/actions";
import config from "../../config";

export const handleFileUpload = (
  dispatch,
  selectedFile,
  setProgress,
  sourceToken,
) => {
  // if (!selectedFile) {
  //   alert("Please select a file to upload.");
  //   return;
  // }

  let filename = selectedFile.name;
  let location_uuid = selectedFile.location_uuid;

  let start_time = new Date();
  const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
  const totalChunks = Math.ceil(selectedFile.size / chunkSize);
  let completed_chunks = [];
  const chunkProgress = 100 / totalChunks;
  // let chunkNumber = 0;
  let chunk_list = Array.from(Array(totalChunks).keys()).reverse();
  let start = 0;
  let end = chunkSize;

  let reqs = chunk_list.map((chunkNumber) => {
    let start = chunkSize * chunkNumber;
    let end = chunkSize * (chunkNumber + 1);
    const chunk = selectedFile.slice(start, end);
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunkNumber", chunkNumber);
    formData.append("totalChunks", totalChunks);
    formData.append("originalname", selectedFile.name);

    return post("/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      cancelToken: sourceToken.token,
    }).then((ret) => {
      const temp = `Chunk ${
        chunkNumber + 1
      }/${totalChunks} uploaded successfully`;
      completed_chunks.push(chunkNumber);
      // setStatus(temp);
      setProgress(filename, (completed_chunks.length / totalChunks) * 100);
      console.log(temp);
      chunkNumber++;
      // start = end;
      // end = start + chunkSize;
      // uploadNextChunk(chunkNumber);
    });
    // .catch((thrown) => {
    //   if (axios.isCancel(thrown)) {
    //     // Handle the cancellation
    //   } else {
    //     // Handle other errors
    //   }

    //   console.log(error);
    //   const temp = `Chunk ${chunkNumber}/${totalChunks} uploaded successfully`;
    //   // setStatus(temp);
    //   setProgress(filename, Number(chunkNumber * chunkProgress));
    //   console.log(temp);
    // });
  });
  Promise.all(reqs)
    .then(() => {
      let end_time = new Date();
      let seconds = (end_time - start_time) / 1000;
      // setUploadTime(seconds);
      post("/complete_upload", {
        fileName: selectedFile.name,
        totalChunks,
        location_uuid,
      }).then(() => {
        setProgress(filename, 100);
        dispatch(refreshFileList());
      });
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        post("/cancel_upload", {
          name: selectedFile.name,
          location_uuid: selectedFile.location_uuid,
        });
      } else {
      }
    });

  // const uploadNextChunk = async () => {
  //   if (chunk_list.length !== 0) {
  //     let chunkNumber = chunk_list.pop();
  //     let start = chunkSize * chunkNumber;
  //     let end = chunkSize * (chunkNumber + 1);
  //     const chunk = selectedFile.slice(start, end);
  //     const formData = new FormData();
  //     formData.append("file", chunk);
  //     formData.append("chunkNumber", chunkNumber);
  //     formData.append("totalChunks", totalChunks);
  //     formData.append("originalname", selectedFile.name);

  //     axios
  //       .post("http://15.222.244.200:8000/upload", formData, {
  //         headers: { "Content-Type": "multipart/form-data" },
  //       })
  //       .then((ret) => {
  //         const temp = `Chunk ${
  //           chunkNumber + 1
  //         }/${totalChunks} uploaded successfully`;
  //         // setStatus(temp);
  //         setProgress(filename, Number((chunkNumber + 1) * chunkProgress));
  //         console.log(temp);
  //         chunkNumber++;
  //         // start = end;
  //         // end = start + chunkSize;
  //         uploadNextChunk(chunkNumber);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         const temp = `Chunk ${chunkNumber}/${totalChunks} uploaded successfully`;
  //         // setStatus(temp);
  //         setProgress(filename, Number(chunkNumber * chunkProgress));
  //         console.log(temp);
  //         // chunkNumber;
  //         // start = end;
  //         // end = start + chunkSize;
  //         // setTimeout(() => uploadNextChunk(chunkNumber), 5000);
  //       });

  //     // fetch("http://15.222.244.200:8000/upload", {
  //     //   method: "POST",
  //     //   body: formData,
  //     // })
  //     //   .then((response) => response.json())
  //     //   .then((data) => {
  //     //     console.log({ data });
  //     //     const temp = `Chunk ${
  //     //       chunkNumber + 1
  //     //     }/${totalChunks} uploaded successfully`;
  //     //     setStatus(temp);
  //     //     setProgress(Number((chunkNumber + 1) * chunkProgress));
  //     //     console.log(temp);
  //     //     chunkNumber++;
  //     //     start = end;
  //     //     end = start + chunkSize;
  //     //     uploadNextChunk();
  //     //   })
  //     //   .catch((error) => {
  //     //     console.error("Error uploading chunk:", error);
  //     //   });
  //   } else {
  //     // setProgress(100);
  //     // setStatus("File upload completed");
  //   }
  // };

  // uploadNextChunk();
  // uploadNextChunk();
  // uploadNextChunk();
  // uploadNextChunk();
  // uploadNextChunk();
  // uploadNextChunk();
};

export const handleFileAddedToQueue = (
  dispatch,
  event,
  location_uuid,
  replace = false,
) => {
  if (!global.files) {
    global.files = {};
  }
  const files = Array.from(event.target.files);
  files.forEach((file) => {
    file.percentage = 0;
    file.location_uuid = location_uuid;
    global.files[file.name] = file;
  });

  const dispatch_payload = files.map((file) => ({
    name: file.name,
    percentage: 0,
    location_uuid,
    replace,
  }));

  dispatch(addFiles(dispatch_payload));
};
