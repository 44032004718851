import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
// import Progress from "../../components/progress";
import { useDispatch, useSelector } from "react-redux";

import { get, post } from "util/requests";
import { DataGrid } from "@mui/x-data-grid";
import { useBlocker, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BreadCrumbs from "./components/bread_crumbs";
import Header from "ui/header";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import NewFolderModal from "views/file_interface/components/new_folder_modal";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import SelectedFileButtons from "ui/buttons/selected_file_buttons";
import UploadStatus from "ui/upload_status";
import moment from "moment";
import { handleFileAddedToQueue } from "ui/upload_status/helpers";
import DeleteAssetModal from "./components/delete_asset_modal";
import { arrayHaveCommonItems } from "util/helpers";
import ReplaceModal from "./components/replace_modal";
import RenameModal from "./components/rename_modal";

const theme = createTheme({
  mixins: {
    MuiDataGrid: {
      // Pinned columns sections
      pinnedBackground: "#340606",
      // Headers, and top & bottom fixed rows
      containerBackground: "#e9f1fd",
    },
  },
});

const FileUpload = () => {
  const [status, setStatus] = useState("");
  const [uploadTime, setUploadTime] = useState(0);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState([
    { name: "RAISE", uuid: "e7e9546b-3a4a-4bd3-9ef9-f16e49249ffb" },
  ]);
  const [addFolderIsOpen, setAddFolderIsOpen] = useState(false);
  const [deleteAssetIsOpen, setDeleteAssetIsOpen] = useState(false);
  const [replaceModalIsOpen, setReplaceModalIsOpen] = useState(false);
  const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const file_select_ref = useRef(null);
  const dispatch = useDispatch();
  const refresh_file_list = useSelector(
    (state) => state.filesReducer.refresh_file_list,
  );
  const navigate = useNavigate();

  useBlocker(
    ({ currentLocation, nextLocation }) => {
      if (location.length <= 1) {
        return true;
      } else {
        fetch_path({}, "up");
        return true;
      }
    },
    // currentLocation.pathname !== nextLocation.pathname,
    // false,
  );

  const get_current_location_uuid = () => location.slice(-1)[0].uuid;

  const fetch_file_list = () =>
    get("/file_data", { params: { uuid: location.slice(-1)[0].uuid } }).then(
      (ret) => {
        setData(ret.data);
      },
    );
  useEffect(() => {
    fetch_file_list();
  }, [refresh_file_list]);

  const fetch_path = (obj, direction = "down") => {
    // console.log("start location", location);
    let _location;
    if (direction === "down") {
      _location = location;
      _location.push(obj);
    } else {
      // console.log("path", obj);
      // console.log("location", location);
      let index = location.findIndex((crumb) => crumb.uuid === obj.uuid);
      // console.log(index);
      if (index === 0 || index === -1) {
        _location = location.slice(0, location.length - 1);
      } else {
        _location = location.slice(0, index + 1);
      }
    }
    // console.log("location after", _location);
    setLocation(_location);
    setSelectedFiles([]);
    get("/file_data", { params: { uuid: _location.slice(-1)[0].uuid } }).then(
      (ret) => {
        setData(ret.data);
        setSelectedFiles([]);
      },
    );
  };

  const createNewFolder = (folder_name) => {
    let uuid = location.slice(-1)[0].uuid;
    post("/folder", { uuid, folder_name }).then((ret) => {
      let new_folder = ret.data;
      fetch_file_list();
      // setData([]);
      // let _location = [...location];
      // _location.push(new_folder);
      // console.log(_location);
      // setLocation(_location);
      setAddFolderIsOpen(false);
    });
  };

  const toggleFileSelection = (uuid, type, name) => {
    let is_selected = selectedFiles.map((sf) => sf.uuid).includes(uuid);
    let _selectedFiles = [...selectedFiles];
    if (is_selected) {
      _selectedFiles = _selectedFiles.filter((sf) => sf.uuid !== uuid);
    } else {
      _selectedFiles.push({ uuid, type, name });
    }
    setSelectedFiles(_selectedFiles);
  };

  const deleteAssets = () => {
    post("/delete_assets", {
      list: selectedFiles,
      parent_uuid: get_current_location_uuid(),
    }).then(() => {
      setDeleteAssetIsOpen(false);
      fetch_file_list();
      setSelectedFiles([]);
    });
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      {/* <h3>{status}</h3>
      {progress}
      <br />
      time: {uploadTime}
      <br />*/}
      <input
        key={Date.now()}
        ref={file_select_ref}
        type="file"
        onChange={(event) => {
          // console.log("files", Array(event.target.files));
          if (event.target.files.length) {
            if (
              arrayHaveCommonItems(
                Array.from(event.target.files).map((f) => f.name),
                data.filter((d) => d.type === "file").map((d) => d.name),
              )
            ) {
              setReplaceModalIsOpen(event);
            } else {
              handleFileAddedToQueue(
                dispatch,
                event,
                location.slice(-1)[0].uuid,
              );
            }
            // event.target.value = null;
          }
        }}
        style={{ display: "none" }}
      />
      <div className={styles.table_wrapper}>
        <div className={styles.table_header}>
          <BreadCrumbs location={location} fetch_path={fetch_path} />
          <SelectedFileButtons
            selectedFiles={selectedFiles}
            setDeleteAssetIsOpen={setDeleteAssetIsOpen}
            setRenameModalIsOpen={setRenameModalIsOpen}
          />

          <div>
            <Button
              className={styles.button}
              onClick={() => setAddFolderIsOpen(true)}
              sx={{
                justifyContent: "center",
              }}
            >
              <AddIcon
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                  marginRight: "2px",
                }}
              />{" "}
              Folder
            </Button>
            <Button
              className={styles.button}
              onClick={() => {
                //this used to call handleFileChange
                file_select_ref.current.click();
              }}
            >
              <FileUploadIcon
                sx={{
                  fontSize: "18px",
                  marginBottom: "0px",
                  marginRight: "2px",
                }}
              />
              Upload
            </Button>
          </div>
        </div>
        <div className={styles.table_container}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={data}
              columns={[
                {
                  field: "selected",
                  headerName: "",
                  width: 30,
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div className={styles.circle_cell}>
                      {selectedFiles.map((sf) => sf.uuid).includes(row.uuid) ? (
                        <CircleIcon sx={{ fontSize: "14px" }} />
                      ) : (
                        <CircleOutlinedIcon sx={{ fontSize: "14px" }} />
                      )}
                    </div>
                  ),
                },
                {
                  field: "type",
                  headerName: "",
                  width: 50,
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div>
                      {row.type === "folder" ? (
                        <FolderIcon
                          sx={{ fontSize: "20px", marginTop: "4px" }}
                        />
                      ) : (
                        <InsertDriveFileOutlinedIcon
                          sx={{ fontSize: "20px", marginTop: "4px" }}
                        />
                      )}
                    </div>
                  ),
                },
                ,
                {
                  field: "name",
                  headerName: "Name",
                  width: 400,
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div>
                      <span
                        className={styles.folder_link}
                        onClick={() => {
                          if (row.type === "folder") {
                            fetch_path({
                              name: row.name,
                              uuid: row.uuid,
                            });
                          }
                        }}
                      >
                        {" "}
                        {row.name}
                      </span>
                    </div>
                  ),
                },
                {
                  field: "modified_on",
                  headerName: "Modified",
                  type: "number",
                  width: 150,
                  align: "center",
                  headerAlign: "center",
                  headerClassName: "columnHeaders",
                  renderCell: ({ row }) => (
                    <div>{moment(row.modified_on).format("MMMM DD, YYYY")}</div>
                  ),
                },
              ]}
              disableColumnSelector
              disableRowSelectionOnClick
              disableColumnMenu
              hideFooterSelectedRowCount
              columnHeaderHeight={34}
              rowHeight={34}
              sx={{
                boxShadow: 0,
                border: 1,
                borderColor: "#f0f0f0",
                "& .MuiDataGrid-cell:hover": {
                  color: "#8349fd",
                  cursor: "pointer",
                },

                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: 700,
                  color: "#333",
                },
                "& .MuiDataGrid-cell:focus": { outline: "none" },
              }}
              onRowClick={(row) => {
                // if (row.row.type === "folder") {
                //   fetch_path({ name: row.row.name, uuid: row.row.uuid });
                // }
                toggleFileSelection(row.row.uuid, row.row.type, row.row.name);
              }}
              onCellClick={(cell) => {
                // if (cell.field === "name" && cell.row.type === "folder") {
                //   fetch_path({ name: cell.row.name, uuid: cell.row.uuid });
                // }
              }}
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              slots={{
                noRowsOverlay: () => (
                  <div className={styles.no_rows_overlay}>Empty Folder</div>
                ),
              }}
            />
          </ThemeProvider>
        </div>
      </div>
      <NewFolderModal
        isOpen={addFolderIsOpen}
        close={() => setAddFolderIsOpen(false)}
        onYes={createNewFolder}
        data={data}
      />
      <DeleteAssetModal
        isOpen={deleteAssetIsOpen}
        close={() => setDeleteAssetIsOpen(false)}
        onYes={deleteAssets}
        selectedFiles={selectedFiles}
      />
      <UploadStatus />
      <ReplaceModal
        isOpen={replaceModalIsOpen}
        close={() => setReplaceModalIsOpen(false)}
        onYes={() => {
          let event = replaceModalIsOpen; //we stored it in the state
          handleFileAddedToQueue(
            dispatch,
            replaceModalIsOpen,
            get_current_location_uuid(),
            true,
          );
          setReplaceModalIsOpen(false);
          event.target.value = null;
        }}
      />
      <RenameModal
        data={selectedFiles}
        allFilesList={data}
        isOpen={renameModalIsOpen}
        close={() => setRenameModalIsOpen(false)}
        onYes={(re_fetch, new_name) => {
          setRenameModalIsOpen(false);
          if (re_fetch) {
            fetch_file_list();
            let _selectedFiles = [...selectedFiles];
            _selectedFiles[0].name = new_name;
            setSelectedFiles(_selectedFiles);
          }
        }}
      />
    </div>
  );
};

export default FileUpload;
