import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "_redux/user/actions";
import { setAuthState } from "_redux/auth/actions";
import styles from "./user_entry_point.module.css";
import ctms_logo from "assets/logo.png";

import {
  useNavigate,
  useLocation,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";

import SignIn from "views/signin";
import BaseRoute from "views/base_route";
import { checkHasActiveSignIn } from "util/auth";

export default function UserEntryPoint() {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth.authState);
  // const authState = true;
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    // navigate("/");
    // console.log("user_entry_point");
    checkHasActiveSignIn()
      .then((ret) => {
        dispatch(setAuthState(true));
        if (ret && ret.user_data) {
          dispatch(setUserData(ret.user_data));
        }
        if (location.pathname === "/") {
          // setSearchParams({ test: "test" });
          // let search = createSearchParams({
          //   g_dom: 1,
          //   g_sit: 1,
          // }).toString();

          navigate({ pathname: "/files" });
        }
      })
      .catch(() => {
        dispatch(setAuthState(false));
        // window.location = "/";
        navigate("/");
      });
  }, [authState]);

  if (authState === "loading") {
    return (
      <div className={styles.loading_wrapper}>
        <img src={ctms_logo} className={styles.logo} />
      </div>
    );
  } else if (authState === true) {
    return <BaseRoute />;
  } else {
    return <SignIn />;
  }
}
