import { useEffect, useState } from "react";
import styles from "./rename_modal.module.css";

import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";
import { Dialog, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { post } from "util/requests";

export default function RenameModal(props) {
  const { isOpen, close, onYes, data, allFilesList } = props;
  const [value, setValue] = useState(
    (data && data.length && data[0].name) || "",
  );
  const [message, setMessage] = useState("");

  useEffect(() => {
    data && data.length && setValue(data && data.length && data[0].name);
  }, [data, isOpen]);

  return (
    <Dialog open={isOpen} onClose={close}>
      <div className={styles.wrapper}>
        <div className={styles.modal_wrapper}>
          <div className={styles.modal_title}>{`Rename:`}</div>
          <div className={styles.modal_body}>
            <div className={styles.data}>
              {data && data.length && data[0].name}
            </div>
            <div className={styles.data}>to</div>
            <TextField
              type="text"
              placeholder=""
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setMessage("");
                // let value = e.target.value;
                // if (/^([0-9]+){0,3}$/.test(value)) {
                //   setSystolic(value);
                // }
                // if (is_error({ systolic: value, diastolic }, "both")) {
                //   setParentFieldError && setParentFieldError(key_id, true);
                // } else {
                //   setParentFieldError && setParentFieldError(key_id, false);
                // }
              }}
              onKeyDown={() => {}}
              onBlur={() => {
                // updateValue("systolic", systolic);
              }}
              autoComplete="off"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "500px",
                  fontSize: 14,
                },
              }}
            />
            <span className={styles.error}>{message}</span>
          </div>

          <div className={styles.modal_button_wrapper}>
            <LoadingButton
              disabled={false}
              variant="contained"
              loading={false}
              onClick={() => {
                if (allFilesList.find((file) => file.name === value)) {
                  setMessage(
                    `${allFilesList.find((file) => file.name === value).type === "folder" ? "Folder" : "File"} with same name already exists.`,
                  );
                  return;
                }
                if (data[0].name !== value) {
                  post("/rename", { existing: data[0], new_name: value }).then(
                    () => {
                      // setValue("");
                      onYes(true, value);
                    },
                  );
                } else {
                  onYes(false);
                }
                // if (
                //   data
                //     .filter((d) => d.type === "folder")
                //     .find((d) => d.name === value)
                // ) {
                //   setMessage("Folder name already exists.");
                // } else {
                //   onYes(value);
                //   setValue("");
                // }
              }}
            >
              Rename
            </LoadingButton>
            <LoadingButton
              disabled={false}
              variant="outlined"
              loading={false}
              onClick={() => {
                setMessage("");
                close();
              }}
            >
              Cancel
            </LoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
