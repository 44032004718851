import React from "react";
import { createBrowserRouter } from "react-router-dom";

import FileInterface from "views/file_interface";
import SignIn from "views/signin";
import UserEntryPoint from "views/user_entry_point";
import InviteCompletion from "views/invite_completion";
import PasswordReset from "views/password_reset";

// const router = createBrowserRouter([{ path: "/*", element: <SignIn /> }]);
const router = createBrowserRouter([
  {
    path: "user_invite_completion/:invite_token",
    element: <InviteCompletion />,
  },
  { path: "/password_reset", element: <PasswordReset /> },
  { path: "/*", element: <UserEntryPoint /> },
]);

export default router;
