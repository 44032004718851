import { useState } from "react";
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { post_raw } from "util/requests";
import ctms_logo from "assets/logo.png";
import router from "views/routes";
import login_styles from "views/signin/index.module.css";

const error_message = "Sorry, your login was not correct.";

export default function SignIn() {
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [show2fa, setShow2fa] = useState(false);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const emailvalid = (value) =>
    value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
      ? true
      : false;

  const handleReset = () => {
    if (is_loading) return;

    if (!emailvalid(email)) {
      return;
    }
    setIsLoading(true);
    post_raw("/auth/reset_password", { email }).then(() => {
      setIsLoading(false);
      setIsSent(true);
    });
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "email") setEmail(e.target.value);
    else if (key === "password") setPassword(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.key === "Enter") {
      handleReset();
    }
  };

  const cancelReset = () => router.navigate("/");

  return (
    <div className={login_styles.wrapper}>
      {!isSent ? (
        <div className={login_styles.login_card}>
          <img src={ctms_logo} width="80px" className={login_styles.logo} />
          <h2 style={{ color: "#333" }}>Reset Your Password</h2>
          <div className={login_styles.margin_b_2}>
            <TextField
              label="Email"
              variant="standard"
              onChange={handleFieldChange("email")}
              onKeyDown={onKeyDown}
            />
          </div>

          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={handleReset}
          >
            RESET PASSWORD
          </LoadingButton>
          {errorMessage ? <div>{errorMessage}</div> : null}
          <div className={login_styles.cancel_button} onClick={cancelReset}>
            Cancel
          </div>
        </div>
      ) : (
        <div className="login-card">
          <img src={ctms_logo} width="400px" className="logo" />

          <div style={{ marginTop: 40 }}>
            We've sent you a reset link. Please check your email.
          </div>
          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={() => router.navigate("/")}
            style={{ width: 200, alignSelf: "center", marginTop: 20 }}
          >
            SIGN IN
          </LoadingButton>
        </div>
      )}
    </div>
  );
}
